<template>
    <v-container fluid>
        <v-form @submit.prevent="getMenus">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" @click="createMenu()" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-row class="flex-nowrap">
                            <v-col cols="1" style="max-width: 80px">
                                <v-btn @click="rollback()" class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="11">
                                <v-subheader class="headline">{{ $t('menu') }}</v-subheader>
                            </v-col>
                        </v-row>

                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                            <v-btn @click="createMenu()"  dark color="indigo">
                                {{ $t('create') }}
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table item-key="id" ref="table" :headers="computedHeaders" :items="menuItems"
                              :options.sync="options" disable-pagination
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="sortable-table elevation-1"
                              hide-default-footer>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="editMenu(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteMenu(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>

    import Sortable from 'sortablejs'
    import {mapGetters} from "vuex"

    export default {
        name: "Menus",
        inject: ['forceRerender'],
        data() {
            return {
                menu: null,
                loading: false,
                sortBy: "sort",
                sortDir: false,
                options: {},
                menuItems: [],
                headers: [
                    {
                        text: "",
                        align: "left",
                        sortable: false,
                        value: "sort",
                    },
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: false,
                        value: "price"
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "name"
                    },
                    {
                        text: this.$t('coffee_house'),
                        align: "left",
                        sortable: false,
                        value: "coffee_house"
                    },
                    {
                        text: this.$t('restaurant'),
                        align: "left",
                        sortable: false,
                        value: "restaurant",
                        hide:true,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'company_id']),
            filteredTypeCompanyItems() {
                return this.type_companyItems.filter(item => item.id === 2 || item.id === 3);
            },
            computedHeaders() {
                const headers = [...this.headers];

                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                if (type === 'coffee_house') {
                    return headers.filter(header => header.value !== 'restaurant');
                }
                if (type === 'restaurant') {
                    return headers.filter(header => header.value !== 'coffee_house');
                }

                // Оставляем колонку если параметр есть
                return headers;
            },
        },
        mounted() {
            let table = this.$refs.table.$el.querySelector("tbody")
            Sortable.create(table, {
                handle: ".handle",
                onEnd: this.dragReorder
            })
        },
        watch: {
            options: {
                handler() {
                    this.getMenus()
                },
                deep: false
            }
        },
        methods: {
            dragReorder({newIndex, oldIndex}) {
                const rowSelected = this.menuItems.splice(oldIndex, 1)[0]
                this.menuItems.splice(newIndex, 0, rowSelected)
                this.saveSortMenu()
            },
            async saveSortMenu() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.menuItems && this.menuItems.length > 0) {
                    for (let i in this.menuItems) {
                        if (this.menuItems[i].id !== 'undefined' && this.menuItems[i].id > 0) {
                            formData.append(`sort[${i}]`, this.menuItems[i].id)
                        }
                    }
                }
                await this.$http
                    .post(`partner/menu/sort`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('menu_sorting_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('menu_sorting_not_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getMenus() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.menu) {
                    params.menu = this.menu
                }

                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                if (type === 'coffee_house') {
                    params.coffee_house = this.$route.params.coffee_house_id
                }
                if (type === 'restaurant') {
                    params.restaurant = this.$route.params.restaurant_id
                }

                if (this.$route.params.category_id) {
                    params.menu_category = this.$route.params.category_id
                }
                await this.$http
                    .get("partner/menu", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.menuItems = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_menus'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteMenu(item) {
                if (confirm(this.$t('delete_menu'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`partner/menu/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('menu_has_been_deleted'))
                            this.getMenus()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('menu_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            rollback(){
                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                if (type === 'coffee_house') {
                    this.$router.push({
                        name: 'menu_category',
                        params: {
                            type: type,
                            coffee_house_id: this.company_id,
                        }
                    })
                }
                if (type === 'restaurant') {
                    this.$router.push({
                        name: 'menu_category',
                        params: {
                            type: type,
                            restaurant_id: this.company_id,
                        }
                    })
                }

                // if (this.$route.params.coffee_house_id) {
                //     this.$router.push({
                //         name: 'menu_category_coffee_house',
                //         params: {
                //             coffee_house_id: this.$route.params.coffee_house_id,
                //         }
                //     })
                // }
                // if (this.$route.params.restaurant_id) {
                //     this.$router.push({
                //         name: 'menu_category_restaurant',
                //         params: {
                //             restaurant_id: this.$route.params.restaurant_id,
                //         }
                //     })
                // }

            },
            createMenu(){
                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                if (type === 'coffee_house') {
                    this.$router.push({
                        name: 'menu.create',
                        params: {
                            type: type,
                            coffee_house_id: this.company_id,
                            category_id: this.$route.params.category_id
                        }
                    })
                }
                if (type === 'restaurant') {
                    this.$router.push({
                        name: 'menu.create',
                        params: {
                            type: type,
                            restaurant_id: this.company_id,
                            category_id: this.$route.params.category_id
                        }
                    })
                }
                //
                // if (this.$route.params.coffee_house_id) {
                //     this.$router.push({
                //         name: 'coffee_house_menu.create',
                //         params: {
                //             coffee_house_id: this.$route.params.coffee_house_id,
                //             category_id: this.$route.params.category_id
                //         }
                //     })
                // }
                // if (this.$route.params.restaurant_id) {
                //     this.$router.push({
                //         name: 'restaurant_menu.create',
                //         params: {
                //             restaurant_id: this.$route.params.restaurant_id,
                //             category_id: this.$route.params.category_id
                //         }
                //     })
                // }

            },
            editMenu(item){
                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                if (type === 'coffee_house') {
                    this.$router.push({
                        name: 'menu.edit',
                        params: {
                            type: type,
                            menu_id: item.id,
                            coffee_house_id: this.company_id,
                            category_id: this.$route.params.category_id
                        }
                    })
                }
                if (type === 'restaurant') {
                    this.$router.push({
                        name: 'menu.edit',
                        params: {
                            type: type,
                            menu_id: item.id,
                            restaurant_id: this.company_id,
                            category_id: this.$route.params.category_id
                        }
                    })
                }

                // if (this.$route.params.coffee_house_id) {
                //     this.$router.push({
                //         name: 'coffee_house_menu.edit',
                //         params: {
                //             menu_id: item.id,
                //             coffee_house_id: this.$route.params.coffee_house_id,
                //             category_id: this.$route.params.category_id
                //         }
                //     })
                // }
                // if (this.$route.params.restaurant_id) {
                //     this.$router.push({
                //         name: 'restaurant_menu.edit',
                //         params: {
                //             menu_id: item.id,
                //             restaurant_id: this.$route.params.restaurant_id,
                //             category_id: this.$route.params.category_id
                //         }
                //     })
                // }
            }
        }
    }
</script>
